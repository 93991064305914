.About {
  color: #000;
  padding: 0 20px;
}
.About p {
  font-size: 14px;
  line-height: 20px;
}
.About a {
  color: #000;
}
.About h2 {
  margin-top: 40px;
}
/*# sourceMappingURL=src/views/About.css.map */